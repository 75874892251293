import Router from 'vue-router'
import Vue from 'vue'
import Layout from '@/layout'
import store from '@/store'
import getPageTitle from "@/utils/get-page-title";

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        redirect: '/home',
        hidden: true,
    },
    {
        path: '/home',
        component: Layout,
        children: [
            {
                path: '/home',
                component: () => import('@/views/home/home'),
                meta: {title: '首页'}
            }
        ]
    },
    {
        path: '/product',
        component: Layout,
        meta: {title: '产品中心', showSubtitle: "yes"},
        children: [
            {
                path: '/product/:id',
                component: () => import('@/views/product/product'),
                meta: {
                    second: [{
                        parent: '电商',
                        third: ['小沐商城','特价购物','跨境电商']
                    }, {
                        parent: '社交',
                        third: ['微聊定位','视频聊天','微视频','小沐对讲']
                    }, {
                        parent: '物联网',
                        third: [ '沐宝卫士', '学生卡','儿童智能手表','蓝牙耳机']
                    },{
                        parent: '游戏',
                        third: ['QQ小程序游戏','电视机游戏']
                    },{
                        parent: '听书教育',
                        third: ['喜马拉雅','贝乐虎','小沐助教','小沐导学']
                    }]
                }
            }
        ]
    },
    {
        path: '/case-detail',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/case-detail/:name',
                component: () => import('@/views/case/case-detail')
            }
        ]
    },
    {
        path: '/case',
        component: Layout,
        children: [
            {
                path: '/case',
                component: () => import('@/views/case/case'),
                meta: {title: '服务案例'}
            }
        ]
    },
    {
        path: '/about',
        component: Layout,
        meta: {title: '关于我们'},
        children: [
            {
                path: '/about/introduction',
                component: () => import('@/views/about/introduction/introduction'),
                meta: {title: '公司介绍'}
            },
            {
                path: '/about/service',
                component: () => import('@/views/about/service/service'),
                meta: {title: '开发服务'}
            },
            {
                path: '/about/join',
                component: () => import('@/views/about/join/join'),
                meta: {title: '加入我们'}
            }
        ]
    },
    {
        path: '/technical-support',
        component: Layout,
        children: [
            {
                path: '/technical-support',
                component: () => import('@/views/technical/technical'),
                meta: {title: '技术支持'}
            }
        ]
    }
]

const createRouter = () => new Router({
    mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()

router.beforeEach(async (to, from, next) => {
    if (store.getters.loadedRoutes) {
        document.title = getPageTitle(to.meta.title)
        next()
    } else {
        await store.dispatch('permission/generateRoutes') // 加载菜单
        next({...to, replace: true})
    }
    // const hasToken = getToken()
    // if (hasToken) {
    //     if (to.path === '/login') {
    //         // if is logged in, redirect to the home page
    //         next({path: '/'})
    //     } else {
    //         if (store.getters.loadedRoutes) {
    //             document.title = getPageTitle(to.meta.title)
    //             next()
    //         } else {
    //             await store.dispatch('permission/generateRoutes') // 加载菜单
    //             next({...to, replace: true})
    //         }
    //     }
    // } else {
    //     if (to.path === '/login') {
    //         next()
    //     } else {
    //         next('/login')
    //     }
    // }
})

export async function resetRouter() {
    await store.dispatch('permission/resetRoutes')
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}


export default router