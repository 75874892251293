<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
    </transition>
    <section-item bgColor="#27272b" height="330px">
      <div class="footer-item-box">
        <img src="../../assets/logo-white.svg" style="width:140px; margin-bottom: 60px"/>
        <span>Copyright © 2024 版权所有 浙江沐恩网络科技有限公司</span>
        <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备19019796号</a>
      </div>
    </section-item>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  width: 100%;
  background: #ffffff;
  overflow:auto;
  margin: 0 auto;
}
.footer-item-box{
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  font-size: 14px;
}
</style>

